.chat-input {
  display: flex;
  border-top: 1px solid #ccc;
  padding: 10px;
  background-color: #fff;
  align-items: center;
  transition: 0.3s;
  height: 57px;
}

.chat-input.with-voice {
  height: 81px;
}

.chat-input.show-voice-recorder {
  height: 160px;
  justify-content: center;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.chat-input .text-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #05a588;
  color: #fff;
  cursor: pointer;
}