.voice-input {
  padding: 0 20px 0 10px;
  position: relative;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: 0.3s;
}

.voice-input button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.voice-input.hidden {
  width: 0;
  opacity: 0;
}

.voice-input .icon {
  background-color: #05a588;
  border-radius: 50%;
  color: white;
  cursor: pointer;
}

.voice-input .icon.disabled {
  background: #e0e0e0;
}

.voice-input .mic {
  font-size: 60px;
  transition: 0.3s;
  padding: 2px;
}

.voice-input .mic svg {
  margin: 2px;
}

.voice-input .mic-stop {
  font-size: 80px;
}

.voice-input .mic.mic_big {
  font-size: 80px;
}

.voice-input .timer {
  padding: 0 10px;
  width: 100px;
  text-align: center;
}

/* HTML: <div class="loader"></div> */
.loader {
  height: 30px;
  margin: 0 20px;
  aspect-ratio: 2.5;
  --_g: no-repeat radial-gradient(farthest-side, #05a588 90%, #0000);
  background: var(--_g), var(--_g), var(--_g), var(--_g);
  background-size: 20% 50%;
  animation: l43 1s infinite linear;
}

.loader.small {
  height: 15px;
}

@keyframes l43 {
  0% {
    background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 50%
  }

  16.67% {
    background-position: calc(0*100%/3) 0, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 50%
  }

  33.33% {
    background-position: calc(0*100%/3) 100%, calc(1*100%/3) 0, calc(2*100%/3) 50%, calc(3*100%/3) 50%
  }

  50% {
    background-position: calc(0*100%/3) 50%, calc(1*100%/3) 100%, calc(2*100%/3) 0, calc(3*100%/3) 50%
  }

  66.67% {
    background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 100%, calc(3*100%/3) 0
  }

  83.33% {
    background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 100%
  }

  100% {
    background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 50%
  }
}