.chat-window {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

.chat-message {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: end;
}


.chat-message .chat-message-item {
    text-align: right;
    margin: 0 15px 15px auto;

}

.chat-message>span {
    padding-right: 10px;
}

.chat-message.chat {
    align-items: start;
}

.chat-sender {
    font-weight: bold;
    text-align: right;

}

.chat .chat-message-item {
    font-size: 20px;

}

.chat-message-item {
    width: 80%;
    font-size: 22px;
}

.chat-message.chat .chat-message-item {
    margin: 0 15px 15px 15px;
}

.chat-message.chat .chat-message-item.loading {
    margin: 0
}

.chat-message.chat .chat-message-item {
    text-align: left;
}

.chat-message.chat .chat-message-style {
    display: flex;
    flex-direction: row;
    align-items: flex-start
}

.chat-message.chat .chat-message-style.loading {
    align-items: center
}