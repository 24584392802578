.text-input {
    display: flex;
    width: 100%;
    overflow: hidden;
    transition: 0.3s;
}

.text-input.hidden {
    width: 0;
}

.text-input button {
    opacity: 1;
    transition: 0.3s;
    width: auto;
}

.text-input button.hidden {
    opacity: 0;
    width: 0;
    padding: 0;
}